import axios from 'axios';
import { MessageBox } from 'element-ui';
import store from '@/store';
import router from '@/router';

export default function setup() {
  axios.interceptors.response.use((response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch('identity/logout');
        router.push('/login');
      } else if (error.response.status === 455) {
        // обработка в app
        return Promise.reject(new Error(error.response.data));
      } else {
        if (error.response && error.response.data && error.response.data.code === 'verr') {
          const msg = new Error();
          msg.code = error.response.data.code;
          msg.errors = error.response.data.errors;
          return Promise.reject(msg);
        }
        let message = null;
        if (error.response && error.response.data
          && typeof error.response.data === 'string') {
          message = `Произошла ошибка: ${error.response.data}`;
        } else if (error.message) {
          message = error.message;
        }

        // если message null то ошибка кастомная? и должна обрабатываться компонентом
        if (message) {
          console.warn(`Произошла ошибка: ${message}`);

          MessageBox.alert(message, 'Произошла ошибка', {
            confirmButtonText: 'OK',
            customClass: 'error-alert',
          });
        }
      }

      return Promise.resolve(error);
    });
}
