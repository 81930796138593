import dictionariesApi from '@/api/dictionaries';

export default {
  namespaced: true,
  state: {
    timeZones: [
      { id: 0, name: 'UTC Всемирное координированное время' },
      { id: 2, name: 'GMT+02:00 Калининградское время' },
      { id: 3, name: 'GMT+03:00 Московское время' },
      { id: 4, name: 'GMT+04:00 Самарское время' },
      { id: 5, name: 'GMT+05:00 Екатеринбургское время' },
      { id: 6, name: 'GMT+06:00 Омское время' },
      { id: 7, name: 'GMT+07:00 Красноярское время' },
      { id: 8, name: 'GMT+08:00 Иркутское время' },
      { id: 9, name: 'GMT+09:00 Якутское время' },
      { id: 10, name: 'GMT+10:00 Владивостокское время' },
      { id: 11, name: 'GMT+11:00 Среднеколымское время' },
      { id: 12, name: 'GMT+12:00 Камчатское время' },
    ],
    organizationTypes: [],
    primaryEquipmentTypes: [],
    primaryEquipmentTypesForEdit: [],
    energyObjectPsrTypes: [],
    primaryEquipmentPsrTypes: [],
    secondEquipmentPsrTypes: [],
    baseVoltages: [],
    operationalZones: [],
    estimations: [],
    shortCircuitForms: [],
    shortCircuitPlaces: [],
    producerOrganizations: [],
    cardInformations: [],
    registrationGroups: [],
    misoperationReasons: [],
    faultedPhases: [],
    executions: [],
    bmmnCheckTypes: [],
    integrationChangesTypes: [],
    integrationJournalStatuses: [],
    decommissionedSE: [
      { id: 0, name: 'только выведенные из эксплуатации функции', code: true },
      { id: 1, name: 'все функции без учета выведенных', code: false },
      { id: 2, name: 'все функции с учетом выведенных', code: null },
    ],
    decommissionedSEP: [
      { id: 0, name: 'только выведенные из эксплуатации устройства', code: true },
      { id: 1, name: 'все устройства без учета выведенных', code: false },
      { id: 2, name: 'все устройства с учетом выведенных', code: null },
    ],
    integrations: [
      { id: 1, name: 'ОИК СК-11' },
    ],
    technicalReasons: [],
    staffCategories: [],
    staffCulpabilities: [],
    eventTypes: []
  },

  getters: {
    timeZones: (state) => state.timeZones,
    organizationTypes: (state) => state.organizationTypes,
    primaryEquipmentTypes: (state) => state.primaryEquipmentTypes,
    primaryEquipmentTypesWithLine: (state) => state.primaryEquipmentTypesWithLine,
    primaryEquipmentTypesForEdit: (state) => state.primaryEquipmentTypesForEdit,
    energyObjectPsrTypes: (state) => state.energyObjectPsrTypes,
    primaryEquipmentPsrTypes: (state) => state.primaryEquipmentPsrTypes,
    secondEquipmentPsrTypes: (state) => state.secondEquipmentPsrTypes,
    baseVoltages: (state) => state.baseVoltages,
    operationalZones: (state) => state.operationalZones,
    estimations: (state) => state.estimations,
    shortCircuitForms: (state) => state.shortCircuitForms,
    shortCircuitPlaces: (state) => state.shortCircuitPlaces,
    producerOrganizations: (state) => state.producerOrganizations,
    cardInformations: (state) => state.cardInformations,
    registrationGroups: (state) => state.registrationGroups,
    registrationGroupsWithNotMfu: (state) => state.registrationGroups.filter((x) => x.name !== 'МФУ' && x.name !== 'МФУ РАСП'),
    misoperationReasons: (state) => state.misoperationReasons,
    faultedPhases: (state) => state.faultedPhases,
    executions: (state) => state.executions,
    bmmnCheckTypes: (state) => state.bmmnCheckTypes,
    integrationChangesTypes: (state) => state.integrationChangesTypes,
    integrationJournalStatuses: (state) => state.integrationJournalStatuses,
    decommissionedSE: (state) => state.decommissionedSE,
    decommissionedSEP: (state) => state.decommissionedSEP,
    integrations: (state) => state.integrations,
    technicalReasons: (state) => state.technicalReasons,
    staffCategories: (state) => state.staffCategories,
    staffCulpabilities: (state) => state.staffCulpabilities,
    eventTypes: (state) => state.eventTypes,
  },

  actions: {
    async fetchDictionaries({ commit }) {
      const response = await dictionariesApi.getDictionaries();
      if (response && response.data) {
        commit('SET_DICTIONARIES', response.data);
      }
    }
  },

  mutations: {
    SET_DICTIONARIES: (state, dictionaries) => {
      state.organizationTypes = dictionaries.organizationTypes;
      state.primaryEquipmentTypes = dictionaries.primaryEquipmentTypes;
      state.primaryEquipmentTypesWithLine = dictionaries.primaryEquipmentTypesWithLine;
      state.primaryEquipmentTypesForEdit = dictionaries.primaryEquipmentTypesForEdit;
      state.energyObjectPsrTypes = dictionaries.energyObjectPsrTypes;
      state.primaryEquipmentPsrTypes = dictionaries.primaryEquipmentPsrTypes;
      state.secondEquipmentPsrTypes = dictionaries.secondEquipmentPsrTypes;
      state.baseVoltages = dictionaries.baseVoltages;
      state.operationalZones = dictionaries.operationalZones;
      state.estimations = dictionaries.estimations;
      state.shortCircuitForms = dictionaries.shortCircuitForms;
      state.shortCircuitPlaces = dictionaries.shortCircuitPlaces;
      state.producerOrganizations = dictionaries.producerOrganizations;
      state.cardInformations = dictionaries.cardInformations;
      state.registrationGroups = dictionaries.registrationGroups;
      state.misoperationReasons = dictionaries.misoperationReasons;
      state.faultedPhases = dictionaries.faultedPhases;
      state.executions = dictionaries.executions;
      state.bmmnCheckTypes = dictionaries.bmmnCheckTypes;
      state.integrationChangesTypes = dictionaries.integrationChangesTypes;
      state.integrationJournalStatuses = dictionaries.integrationJournalStatuses;
      state.technicalReasons = dictionaries.technicalReasons;
      state.staffCategories = dictionaries.staffCategories;
      state.staffCulpabilities = dictionaries.staffCulpabilities;
      state.eventTypes = dictionaries.eventTypes;
    },
  }
};
